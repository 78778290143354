import clsx from 'clsx';

import styles from './DocumentationButton.module.scss';

const DocumentationIcon = () => (
  <svg width='22' height='23' viewBox='0 0 22 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M20 6.7069V8H2V6.7069C2 4.63793 3.71 3 5.87 3H16.22C18.29 3 20 4.63793 20 6.7069Z' fill='currentcolor' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M20 9.69804H2V16.2021C2 18.1533 3.71 19.698 5.87 19.698H16.13C18.29 19.698 20 18.072 20 16.2021V9.69804ZM16.13 13.6818H5.86997C5.32997 13.6818 4.96997 13.3566 4.96997 12.8687C4.96997 12.3809 5.32997 12.0557 5.86997 12.0557H16.22C16.76 12.0557 17.12 12.3809 17.12 12.8687C17.12 13.3566 16.67 13.6818 16.13 13.6818ZM16.13 17.0151H5.86997C5.32997 17.0151 4.96997 16.6899 4.96997 16.2021C4.96997 15.7143 5.32997 15.3891 5.86997 15.3891H16.22C16.76 15.3891 17.12 15.7143 17.12 16.2021C17.12 16.6899 16.67 17.0151 16.13 17.0151Z'
      fill='currentcolor'
    />
  </svg>
);

export const DocumentationButton = ({
  href,
  className,
  shrink,
}: {
  href: string;
  className?: string;
  shrink?: boolean;
}) => {
  return (
    <a
      href={href}
      target='_blank'
      rel='noreferrer'
      className={clsx(styles.documentation, className, shrink && styles.shrink)}>
      <DocumentationIcon />
      <span>Documentation</span>
    </a>
  );
};
