import clsx from 'clsx';
import Image from 'next/image';

import { Button, Pill, cardClassNames } from '@endaoment-frontend/ui/shared';

import { DocumentationButton } from '../../components/DocumentationButton';
import { GithubButton } from '../../components/GithubButton';
import { DEV_LINKS, FEATURE_LIST } from '../../editable';
import apiLogo from '../../images/logos/api.svg';

import styles from './ApiSection.module.scss';

export type FeatureList = Readonly<
  Array<{
    title: string;
    description: string;
    comingSoon?: boolean;
  }>
>;

export const ApiSection = () => {
  return (
    <section className={styles.wrapper} id='api'>
      <div className={styles.container}>
        <Image src={apiLogo} alt='' width={76} height={72} />
        <h2>API Features</h2>
        <p>
          We’ve built a lightweight, public endpoint for programming customizable interactions with the Endaoment
          protocol.
        </p>
        <Button as='a' href={DEV_LINKS.api.examples} variation='purple' filled className={styles['try-button']}>
          Try it yourself
        </Button>
        <div className={styles.links}>
          <GithubButton href={DEV_LINKS.api.github} variation='purple' />
          <DocumentationButton href={DEV_LINKS.api.documentation} />
        </div>
        <ul className={styles.list}>
          {FEATURE_LIST.map(({ title, description, comingSoon }) => (
            <li key={title} className={clsx(cardClassNames.base, comingSoon && styles['feature-card__coming-soon'])}>
              <h4>{title}</h4>
              <p>{description}</p>
              {!!comingSoon && (
                <Pill size='tiny' variation='purple' className={styles['coming-soon']} fill>
                  Coming soon
                </Pill>
              )}
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};
