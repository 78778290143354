import Image from 'next/image';

import { MailIcon } from '@endaoment-frontend/ui/icons';
import { Pill } from '@endaoment-frontend/ui/shared';

import { SdkCard } from '../../components/SdkCard';
import maleCharacter from '../../images/characters/male.png';

import styles from './SdkLink.module.scss';

export const SdkLink = () => {
  return (
    <section className={styles.wrapper}>
      <div className={styles.container}>
        <Image src={maleCharacter} alt='' width={300} height={660} className={styles['ipad-dude']} />
        <div className={styles.text}>
          <h2>On-chain programmable philanthropy</h2>
          <SdkCard withIcon />
          <div className={styles.cta}>
            Have questions? Reach out to our team at
            <br />
            <a href='mailto:partnerships@endaoment.org'>
              <MailIcon /> partnerships@endaoment.org
            </a>
          </div>
        </div>
        <div className={styles['try']}>
          <Pill fill variation='purple' size='tiny'>
            Try it out!
          </Pill>
          <svg
            className={styles['try-lines']}
            width='574'
            height='171'
            viewBox='0 0 574 171'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_6608_12324)'>
              <rect width='574' height='171' fill='white' />
              <path
                opacity='0.75'
                d='M-0.5 11H382H549C557.837 11 565 18.1634 565 27V124.75C565 133.587 557.837 140.75 549 140.75H387.5C378.663 140.75 371.5 147.913 371.5 156.75V171'
                stroke='#627EEA'
                strokeDasharray='5 5'
              />
            </g>
            <defs>
              <clipPath id='clip0_6608_12324'>
                <rect width='574' height='171' fill='white' />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </section>
  );
};
