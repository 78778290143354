import Image from 'next/image';

import { Button } from '@endaoment-frontend/ui/shared';

import { DocumentationButton } from '../../components/DocumentationButton';
import { GithubButton } from '../../components/GithubButton';
import { DEV_LINKS } from '../../editable';
import queryBlock from '../../images/code/query.svg';
import resultBlock from '../../images/code/result.svg';
import poweredByGraph from '../../images/icons/powered-by-graph.svg';
import subgraphLogo from '../../images/logos/graph.svg';

import styles from './SubgraphSection.module.scss';

export const SubgraphSection = () => (
  <section className={styles['subgraph__wrapper']} id='subgraph'>
    <div className={styles['subgraph__container']}>
      <div>
        <Image src={subgraphLogo} alt='' width={76} height={72} />
        <h2>A queryable subgraph for all things Endaoment</h2>
        <p>Pull statistics, find contract addresses and format transactions with straightforward GraphQL</p>
        <div className={styles.center}>
          <Button
            as='a'
            target='_blank'
            rel='noreferrer'
            href={DEV_LINKS.subgraph.examples}
            variation='org'
            filled
            className={styles['visit-button']}>
            Visit the Playground
          </Button>
          <Image src={poweredByGraph} alt='' width={160} height={20} />
        </div>
        <div className={styles.links}>
          <GithubButton href={DEV_LINKS.subgraph.github} variation='blue' />
          <DocumentationButton href={DEV_LINKS.subgraph.documentation} />
        </div>
      </div>
      <div>
        <div>
          <span>Query</span>
          <Image src={queryBlock} alt='GraphQL query for Endaoment data' />
        </div>
        <div>
          <span>Result</span>
          <Image src={resultBlock} alt='GraphQL result for Endaoment data' />
        </div>
      </div>
    </div>
  </section>
);
