import { Link } from '@chakra-ui/next-js';
import clsx from 'clsx';
import Image from 'next/image';

import { Card } from '@endaoment-frontend/ui/shared';

import folderIcon from '../images/icons/folder.png';
import apiLogo from '../images/logos/api.svg';
import graphLogo from '../images/logos/graph.svg';
import Logo from '../images/logos/logo.svg';

import styles from './SdkCard.module.scss';

export const SdkCard = ({ withIcon = false, className }: { withIcon?: boolean; className?: string }) => (
  <Card className={clsx(styles.container, className)}>
    <p className={styles.text}>
      <Image src={Logo} height={34} width={180} alt='Endaoment developers logo' />
      <span>Plug in to our ecosystem</span>
    </p>
    <Link href='#api'>
      <Image src={apiLogo} width={76} height={72} alt='' />
    </Link>
    <Link href='#subgraph'>
      <Image src={graphLogo} width={76} height={72} alt='' />
    </Link>
    {!!withIcon && <Image src={folderIcon} alt='' width={100} height={100} className={styles.icon} />}
  </Card>
);
