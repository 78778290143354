import { Link } from '@chakra-ui/next-js';
import clsx from 'clsx';
import Image from 'next/image';

import { GetFeaturedOrgs } from '@endaoment-frontend/api';
import { OrgLogo, Pill, cardClassNames } from '@endaoment-frontend/ui/shared';

import femaleCharacter from '../../images/characters/female-shadow.png';
import Folder from '../../images/icons/folder-shadow.png';
import Smiley from '../../images/icons/smiling-shadow.png';
import apiLogo from '../../images/logos/api.svg';
import graphLogo from '../../images/logos/graph.svg';

import styles from './Hero.module.scss';

const BackgroundLines = () => (
  <div className={styles['lines-container']}>
    <svg width='6000' height='740' viewBox='0 0 6000 740' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_6872_12379)'>
        <rect width='6000' height='740' fill='white' />
        <path
          opacity='0.5'
          d='M6000 501L3015 501C3006.17 501 2999 508.163 2999 517L2999 740'
          stroke='#627EEA'
          strokeDasharray='5 5'
        />
        <path
          opacity='0.5'
          d='M3001 0V107C3001 115.837 2993.84 123 2985 123H0'
          stroke='#627EEA'
          strokeDasharray='5 5'
        />
        <path opacity='0.5' d='M3362 0L3362 1791' stroke='#627EEA' strokeDasharray='5 5' />
        <path
          opacity='0.5'
          d='M0 440L2790 440C2798.84 440 2806 432.837 2806 424L2806 335C2806 326.164 2813.16 319 2822 319L6000 319'
          stroke='#627EEA'
          strokeDasharray='5 5'
        />
      </g>
      <defs>
        <clipPath id='clip0_6872_12379'>
          <rect width='6000' height='740' fill='white' />
        </clipPath>
      </defs>
    </svg>
  </div>
);

const ArrowIcon = () => (
  <svg width='11' height='22' viewBox='0 0 11 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M1 1.5L10 11L1 20.5' stroke='#ADBACC' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);

export const Hero = () => {
  const featuredOrgsResult = GetFeaturedOrgs.useQuery([8]);
  return (
    <section className={styles['hero__container']}>
      <div className={styles['hero__content']}>
        <BackgroundLines />
        <span className={styles['person']}>
          <Image src={femaleCharacter} width={520} height={820} alt='Mascot' priority />
        </span>
        <span className={styles['smiley']}>
          <Image src={Smiley} width={260} height={260} alt='Decorative smiley face' />
        </span>
        <span className={styles['folder']}>
          <Image src={Folder} width={260} height={260} alt='Decorative 3D folder' />
        </span>
        <div className={styles['developers-info']}>
          <h3>On-chain programmable philanthropy</h3>
          <h5>Integrate impact directly into your app or website</h5>
        </div>
        <ul className={clsx(cardClassNames.base, styles['feature-list'])}>
          <li>
            <Link href='#api' className={styles['link--api']}>
              <Image src={apiLogo} width={76} height={72} alt='' />
              <p className={styles['new-feature']}>
                <div>Customize your own Endaoment integration through our public endpoints, now with L2 support</div>
                <Pill size='tiny'>NEW!</Pill>
              </p>
              <ArrowIcon />
            </Link>
          </li>
          <li>
            <Link href='#subgraph' className={styles['link--graph']}>
              <Image src={graphLogo} width={76} height={72} alt='' />
              <p>Query a broad range of statistics and information from across the ecosystem through our subgraph</p>
              <ArrowIcon />
            </Link>
          </li>
        </ul>
      </div>
      <div className={styles.sponsors}>
        <p className={styles.sponsors__label}>Create smart donations to 1.8 million nonprofits</p>
        <ul>
          {featuredOrgsResult.data?.map(org => (
            <li key={org.id}>
              <OrgLogo orgName={org.name} src={org.logo} width={120} height={60} hideFallback />
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};
