import clsx from 'clsx';

import { GithubIcon } from '@endaoment-frontend/ui/icons';

import styles from './GithubButton.module.scss';

export const GithubButton = ({
  href,
  className,
  shrink,
  variation,
}: {
  href: string;
  className?: string;
  shrink?: boolean;
  variation?: 'blue' | 'purple';
}) => {
  return (
    <a
      href={href}
      target='_blank'
      rel='noreferrer'
      className={clsx(
        styles.github,
        className,
        shrink && styles.shrink,
        variation === 'purple' && styles.purple,
        variation === 'blue' && styles.blue,
      )}>
      <GithubIcon width={18} />
      <span>Github</span>
    </a>
  );
};
